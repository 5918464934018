import React from 'react';

function Footer() {
	return (
		<footer style={{ fontSize: '0.33rem', padding: '5px', textAlign: 'center' }}>
			<p>🏴‍☠️</p>
			<p>&copy; 2024 CS2 Console Docs. All rights reserved.</p>
		</footer>
	);
}

export default Footer;